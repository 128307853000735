<template>
  <div>
    <Navigation></Navigation>
    <div class="index">
      <agile :autoplay="true" :fade="true" :navButtons="false">
          <img class="slide" src="static/slider-poole/oriel-resturant-1.jpg" alt="">
          <img class="slide" src="static/slider-poole/oriel-resturant-2.jpg" alt="">
          <img class="slide" src="static/slider-poole/oriel-resturant-3.jpg" alt="">
          <img class="slide" src="static/slider-poole/oriel-resturant-4.jpg" alt="">
          <img class="slide" src="static/slider-poole/oriel-resturant-5.jpg" alt="">
          <img class="slide" src="static/slider-poole/oriel-resturant-6.jpg" alt="">
          <img class="slide" src="static/slider-poole/oriel-resturant-7.jpg" alt="">
          <img class="slide" src="static/slider-poole/oriel-resturant-8.jpg" alt="">
          <img class="slide" src="static/slider-poole/oriel-resturant-9.jpg" alt="">
          <img class="slide" src="static/slider-poole/oriel-resturant-10.jpg" alt="">
          <img class="slide" src="static/slider-poole/oriel-resturant-11.jpg" alt="">
      </agile>
      <menu></menu>
      <about-us id="about-us"></about-us>
      <where id="where"></where>
      <contact id="contact"></contact>
    </div>
    <Footer></Footer>
    <cookie-law theme="base"></cookie-law>
  </div>
</template>

<script scoped>
/* eslint-disable */
import Navigation from './navigation.vue'
import Footer from './footer.vue'
import AboutUs from './about-us.vue'
import Where from './where.vue'
import Contact from './contact.vue'
import CookieLaw from 'vue-cookie-law'
import request from 'superagent'
import { VueAgile } from 'vue-agile'

export default {
  name: 'OrielQuay',
  data () {
    return {
      heading: 'Oriel on the Quay'
    }
  },
  components: {
    Navigation,
    Footer,
    agile: VueAgile,
    AboutUs,
    Where,
    Contact,
    CookieLaw
  },
  methods: {}
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only-->
<style scoped>
body {
  margin-right: -2px;
}

h1, h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>

<style>
.agile__nav-button {
    background: transparent;
    border: none;
    color: #fff;
    cursor: pointer;
    font-size: 24px;
    height: 100%;
    position: absolute;
    top: 0;
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
    width: 80px
}

.agile__nav-button:hover {
    background-color: rgba(0, 0, 0, .5);
    opacity: 1
}

.agile__nav-button--prev {
    left: 0
}

.agile__nav-button--next {
    right: 0
}

.agile__dots {
    bottom: 10px;
    left: 50%;
    position: absolute;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%)
}

.agile__dot {
    margin: 0 10px
}


.agile__dot button {
    background-color: transparent;
    border: 1px solid #fff;
    border-radius: 50%;
    cursor: pointer;
    display: block;
    height: 10px;
    font-size: 0;
    line-height: 0;
    margin: 0;
    padding: 0;
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
    width: 10px
}

.agile__dot--current button, .agile__dot:hover button {
    background-color: #fff
}

.agile .slide {
    display: block;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    height: 600px;
}

.slide {
  opacity: 0;
}

</style>
