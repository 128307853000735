<template>
  <div class="contact-us">
    <h2 class="padded">Contact Us</h2>

    <p> Please use the contact form below for bookings and enquiries</p>
    <b-container class="bv-example-row">
        <b-row>
            <b-col cols="12" md="6">
              <h4 class="padding">Contact Information</h4>
              <p>
                <b-img src="static/051__phone.svg" alt="Black and white phone icon" style="width:2rem; height:2rem;" />Telephone Number: <a class="link" href="tel:01202679833">(01202) 679 833</a>
              </p>
              <p>
                <b-img src="static/049__mail.svg" alt="Black and white envelope icon" style="height:2rem;" />
                Email Address: <a class="link" href="mailto:orielonthequay@gmail.com">orielonthequay@gmail.com</a>
              </p>

              <h4 class="padding">Opening Times</h4>
              <ul class="list-unstyled spaced">
                <li>
                  <p>Monday - Sunday: 9am - late</p>
                </li>
              </ul>

              <h4 class="padding">Social Media</h4>
              <b-container class="bv-example-row social-media">
                <b-row>
                 <b-col>
                  <a href="https://www.instagram.com/orielrestaurant/"><b-img src="static/instagram.svg" alt="Instagram logo" style="width:2rem; height:2rem;" /></a>
                  <a href="https://www.facebook.com/Orielrestaurant.co.uk/"><b-img src="static/f-ogo.svg" alt="Blue f facebook logo" style="width:2rem; height:2rem;" /></a>
                  <a href="https://www.tripadvisor.co.uk/Restaurant_Review-g190727-d802373-Reviews-Oriel_Cafe_Restaurant-Poole_Dorset_England.html"><b-img src="static/trip-advisor.png" alt="Green owl trip advisor logo" style="height:2rem;" /></a>
                  <a href="https://www.google.com/maps/place/Oriel+on+the+Quay/@50.7122217,-1.9864036,15z/data=!4m5!3m4!1s0x0:0x7e609b7f3b21dfd!8m2!3d50.7122217!4d-1.9864036"><b-img src="static/google-reviews-icon.png" alt="Google G logo" style="height:2rem;" /></a>
                 </b-col>
                </b-row>
              </b-container>
            </b-col>

            <b-col cols="12" md="6">
              <div id="contact" class="main" style="transition: height 1s ease; overflow:hidden; background-color: #fff;">
                <b-alert show variant="success" v-show="sent">
                  Your message has been sent, Thank you!
                  <b-button v-on:click="sent = !sent" variant="link" style="margin-top: 0;">Send another.</b-button>
                </b-alert>
                <b-alert show variant="error" v-show="errorSending">
                  There was an error sending your message, please try again. Your message has not been sent.
                </b-alert>
                <b-form @submit="onSubmit" @reset="onReset" v-show="!sent">
                  <div class="mainForm">

                    <b-form-group id="customerName" label="Your Name:">
                        <b-form-input id="customerName"
                                      v-model="form.customer.name"
                                      required
                                      placeholder="Enter your name">
                        </b-form-input>
                      </b-form-group>
                      <b-form-group id="customerEmail" label="Your Email:">
                        <b-form-input id="customerEmail"
                                      type="email"
                                      v-model="form.customer.email"
                                      required
                                      placeholder="Enter your email address">
                        </b-form-input>
                      </b-form-group>
                      <b-form-group id="customerPhone" label="Your Telephone Number:">
                        <b-form-input id="customerPhone"
                                      type="tel"
                                      v-model="form.customer.phone"
                                      required
                                      placeholder="Enter your telephone number">
                        </b-form-input>
                      </b-form-group>
                      <b-form-group id="customerMessage" label="Message:">
                        <b-form-textarea  id="message"
                                          v-model="form.customer.message"
                                          required
                                          placeholder="Enter your message"
                                          :rows="7"
                                          :max-rows="10">
                        </b-form-textarea>
                      </b-form-group>
                    </div>
                    <div class="submit">
                      <b-button type="submit" variant="success" :disabled="sending">{{sending ? "Sending..." : "Submit" }}</b-button>
                      <recaptcha ref="recaptcha" @verify="onSubmit"></recaptcha>
                    </div>
                  </b-form>
              </div>
            </b-col>
        </b-row>
    </b-container>
  </div>
</template>

<script>
/* global ga */
import recaptcha from './recaptcha'
export default {
  components: {
    recaptcha
  },
  data () {
    return {
      form: {
        customer: {
          email: '',
          name: '',
          message: ''
        },
        contact: ''
      },
      show: true,
      sent: false,
      errorSending: false,
      sending: false
    }
  },
  methods: {
    sendJson (data, url, cb, error) {
      const xhr = new XMLHttpRequest()
      xhr.open('POST', url, true)
      xhr.setRequestHeader('Content-type', 'application/json')
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4 && xhr.status === 200) {
          cb()
        }
      }
      xhr.onerror = () => {
        error()
      }
      xhr.send(JSON.stringify(data))
    },
    onSubmit (evt) {
      const self = this
      evt && evt.preventDefault && evt.preventDefault()
      this.sending = true
      this.error = false
      this.sendJson(this.form, 'https://us-central1-oriel-restaurant.cloudfunctions.net/contact', () => {
        self.sent = true
        self.sending = false
        self.$forceUpdate()
        self.onReset({ preventDefault: () => {} })
        ga('send', {
          hitType: 'event',
          eventCategory: 'Form',
          eventAction: 'Submitted',
          eventLabel: 'Contact'
        })
      }, () => {
        self.sent = false
        self.sending = false
        self.error = true
        self.$forceUpdate()
        ga('send', {
          hitType: 'event',
          eventCategory: 'Form',
          eventAction: 'Error',
          eventLabel: 'Contact'
        })
      })
    },
    onReset (evt) {
      evt.preventDefault()
      // Reset our form values
      this.form.email = ''
      this.form.name = ''
      this.form.message = ''
      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => { this.show = true })
      ga('send', {
        hitType: 'event',
        eventCategory: 'Form',
        eventAction: 'Reset',
        eventLabel: 'Contact'
      })
    }
  }
}
</script>

<style scoped>
  .contact-us {
    background-color: #f8f9fa;
  }

  button {
    margin-top: 1em;
    padding-left: 1.5em;
    padding-right: 1.5em;
  }

  @media (min-width: 768px) {
    .mainForm {
      margin: 0 3em;
    }
  }

  .mainForm{
    margin: 0 1em;
  }

  .center {
    text-align: center;
  }

  .rd {
    margin-top: 2em;
    margin-bottom: 1.5em;
  }

  .verticalAlign {
    line-height: 1em;
    vertical-align: middle;
  }

  .by {
    line-height: 2.5em;
  }

  .submit{
    text-align: center;
  }

  input{
    margin:0;
  }

  @media (min-width: 768px) {
    .col-1-5 {
      flex: 0 0 10% !important;
      max-width: 10% !important;
    }
    
    .col-5-5 {
      flex: 0 0 45% !important;
      max-width: 45% !important;
    }

    .main {
      padding: 3em;
      margin: 3em auto;
      border: 2px solid #e3e3e3;
      border-radius: 4px;
    }
  }

	.main {
    text-align: left;
		padding: 3em 1em;
    max-width: 37em;
    margin: 1em auto;
	}

  .padding {
    padding-top: 2rem;
  }

  .link {
    color: #5082d3;
  }

  .social-media {
      padding-bottom: 2rem;
  }
</style>

<style>
  legend {
    font-size: 1rem !important;
  }
</style>