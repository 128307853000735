import Vue from 'vue'
import Router from 'vue-router'
import OrielQuay from '@/components/oriel-quay'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'OrielQuay',
      component: OrielQuay
    },
    {
      path: '/oriel-quay',
      name: 'OrielQuay',
      component: OrielQuay
    }
  ]
})
